.reviews h4, .reviews h1{
  color: #fcba00;
  font-size: 28px;
  text-align: center;
  margin: 30px auto;
}

.reviews h1{
  font-size: 34px;
}

.reviews-form-section {
  width: 40%;
  min-width: 250px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.main_form {
  padding:0px;
}

.main_form .form-row {
  display: flex;
  flex-wrap: wrap;
}

.main_form .form-group {
  width: 100%;
}

.main_form .contactus {
  border: inherit;
  margin-bottom: 20px;
  width: 100%;
  height: 60px;
  background: transparent;
  color: #777977;
  font-size: 18px;
  font-weight: normal;
  border-bottom: #ddd solid 1px;
}

.main_form .textarea {
  border: inherit;
  margin-bottom: 20px;
  width: 100%;
  background: transparent;
  color: #777977;
  font-size: 18px;
  font-weight: normal;
  border-bottom: #ddd solid 1px;
  margin-top: 10px;
}

.button-group {
  width: 100%;
  text-align: left;
}

.main_form .send_btn {
  font-size: 17px;
  transition: ease-in all 0.5s;
  background-color:transparent;
  text-transform: uppercase;
  color: #fcba00;
  border: 3px solid #fcba00;
  padding: 10px 0px;
  max-width: 160px;
  font-weight: bold;
  width: 100%;
  display: block;
  margin-top: 30px;
}

@media (hover: hover) {
  .main_form .send_btn:hover {
    background-color: #fcba00;
    transition: ease-in all 0.5s;
    color: black;
  }
}

@media (max-width:450px) {
  .reviews-form-section{
    margin-bottom: 30px;
  }
  .reviews h4{
    font-size: 24px;
  }
}