.staff-title{
    color: #fcba00;
    text-align: center;
    margin: 30px auto;
}

@media (max-width: 570px) {
  .user-image{
    width: 75px;
    max-height: 75px;
  }
  .staff-title{
    margin: 15px 0;
  }
}

@media (max-width: 450px) {
  .staff-title{
    font-size: 24px;
  }
}