.main-header {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  padding: 20px;
  position: relative;
}

.header-img{
  position: absolute;
  width: 90%;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 0;
  z-index: -1;
}
  
.header-content {
  background: rgba(0, 0, 0, 0.8); /* Adding a dark overlay for better text visibility */
  padding: 20px;
  border-radius: 10px;
}

.main-header h1 {
  font-size: 36px;
  margin-bottom: 10px;
  color: yellow;
}

.main-header p {
  color: yellow;
  font-size: 25px;
  margin-bottom: 20px;
}

.header-btn {
  background-color: transparent;
  color: #fcba00;
  padding: 10px 20px;
  height: 50px;
  width: 200px;
  text-decoration: none;
  border-radius: 5px;
  border: 3px solid #fcba00;
  font-weight: bold;
  font-size: 16px;
  transition: 1s;
}

@media (hover: hover) {
  .header-btn:hover {
    background-color: #fcba00;
    color: black;
  }
}
/* Responsive styles */
@media (max-width: 768px) {
  .main-header {
    height: 50vh;
    min-height: auto;
  }

  .main-header h1 {
    font-size: 28px;
  }
  
  .main-header p {
    font-size: 16px;
  }
  
  .btn {
    padding: 8px 16px;
  }
}

@media (max-width: 580px) {
  .header-img{
    width: 100%;
  }
  .main-header h1{
    font-size: 25px;
  }
  .main-header p{
    font-size: 14px;
  }
  .header-btn{
    font-size: 14px;
    height: 40px;
    width: 170px;
  }
}

@media (max-width: 480px) {
  .main-header {
    height: 40vh;
  }  
  .main-header h1 {
    font-size: 24px;
  }
  .main-header p {
    font-size: 18px;
  }
  .header-btn {
    padding: 6px 12px;
  }
}
  