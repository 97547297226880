*{
  margin: 0;
  padding: 0;
}

body{
  background: black;
}

a{
  text-decoration: none;
}

button{
  cursor: pointer;
}