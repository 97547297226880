.skeleton{
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    height: 300px;
}

@media (max-width: 768px) {
    .skeleton-item{
        width: 90% !important;
    }
    .skeleton-item:nth-child(2){
        display: none;
    }
}
