/* Reset some default browser styles */  
  /* about header styles */
  .about-header {
    background: url('https://www.hdwallpapers.in/download/porsche_911_turbo_s_yellow_car_dark_background_4k_5k_hd_cars-2560x1440.jpg') no-repeat center center/cover;
    min-height: 650px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    padding: 20px;
  }
  
  .about-header_content {
    background: rgba(0, 0, 0, 0.7); 
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
}
  
  .about-header h1 {
    color: yellow;
    font-size: 36px;
    margin-bottom: 10px;
  }
  
  .about-header p {
    font-size: 26px;
    margin-bottom: 20px;
    max-width: 70%;
  }

  .offers{
    display: flex;
    justify-content: space-evenly;
  }

  .offer-item{
    width: 30%;
    /* background: #fcba00; */
    height: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .offer-item p{
    color: #f8e703;
    max-width: 75%;
    text-align: center;
    font-size: 25px;
  }

  .offer-item img{
    width: 100px;
  }

  .offer-item:nth-child(1) img{
    filter: none;
  }

  
  .offer-item img{
    filter: brightness(0) saturate(100%) invert(77%) sepia(99%) saturate(552%) hue-rotate(5deg) brightness(98%) contrast(90%);
  }


  /* Responsive styles */
@media (max-width: 768px) {
  .about-header {
    height: 50vh;
    min-height: 490px;
    padding-bottom: 0;
  }
  .offer-item p{
    font-size: 20px;
    max-width: 85%;
  }

  .offer-item{
    height: 200px;
  }

  .about-header h1 {
    font-size: 28px;
  }

  .about-header p {
    font-size: 16px;
  }
}

@media (max-width: 550px) {
  .offer-item{
    justify-content: flex-start;
    gap: 20px;
  }

  .offer-item img{
    width: 70px;
  }
}


  @media (max-width: 480px) {
    .about-header {
      height: 40vh;
    }
  
    .about-header h1 {
      font-size: 24px;
    }
  
    .about-header p {
      font-size: 14px;
    }
}
  