.carousel-container {
  width: 80%;
  overflow: hidden; /* Ensure no overflow */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  padding: 0 40px; /* Optional: adjust padding as needed */
  margin: 0 auto;
}
  

.user-card {
  background: black;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  min-height: 270px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 90%; /* Adjust for 2 cards per slide */
  text-align: center;
  box-sizing: border-box; /* Ensure padding and border are included in the width */
}

.user-card__head{
  display: flex;
  gap: 30px;
}

.user-image {
  width: 100px;
  max-height: 100px;
  object-fit: cover;
  height: auto;
  border-radius: 50%;
  margin-bottom: 10px;
}
  
  .user-card h3 {
    margin: 10px 0;
    font-size: 1.5em;
    color: white;
  }
  
.user-card p {
    margin: 5px 0;
    font-size: 1em;
    color: #fcba00;
}

.user-card .user-card__info{
    color: white;
  }
  
.slick-list {
    overflow: hidden; /* Hide overflow to prevent horizontal scroll */
}
  
.slick-track {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .user-card {
    max-width: 95%;
  }
}

@media (max-width: 570px) {
  .user-image{
    width: 75px;
    max-height: 75px;
  }
  .carousel-container{
    margin-bottom: 30px;
  }
}

@media (max-width: 410px) {
  .carousel-container{
    width: 100%;
  }
  .user-card h3 {
    font-size: 18px;
  }
  .user-card p{
    font-size: 14px;
  }
  .user-card {
    padding: 10px;
  }
}

@media (max-width: 360px) {
  .user-card__head{
    gap: 10px;
  }
  .user-image{
    width: 75px;
    max-height: 75px;
  }
  .user-card h3{
    margin: 5px 0;
  }
}
