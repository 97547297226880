.brands{
    width: 100%;
    display: flex;
    justify-content: center;
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap;
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    -ms-overflow-style: none; 
    background-color: black;
    gap: 40px;
}

.brands::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.brand-item{
    height: 60px;
}

@media (max-width: 680px) {
    .brand-item{
        height: 45px;
    }
}

@media (max-width: 500px) {
    .brand-item{
        height: 30px;
    }
    .brands{
        gap: 20px;
    }
}