/* Reset some default browser styles */
.footer {
  padding: 40px 50px 15px 50px;
  background: black;
  text-align: center;
}

.footer .container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer .row {
  justify-content: space-between;
}

.titlepage {
  padding-bottom: 0px;
  width: 100%;
  gap: 50px;
  display: flex;
}

.titlepage h5 {
  color: #fff;
  margin: inherit;
  font-size: 27px;
  max-height: 28px;
  text-decoration: underline;
}

.contact-section {
  flex: 2;
  min-width: 250px;
  margin-bottom: 20px;
}

ul.location_icon {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 15px;
  list-style: none;
  padding-left: 0;
}

ul.location_icon li {
  display: inline-block;
  font-size: 18px;
  line-height: 20px;
  color: #f8e703;
  font-weight: 600;
}

ul.location_icon li a {
  font-size: 30px;
  color: #fff;
  line-height: 30px;
  padding-right: 15px;
  text-decoration: none;
}

@media (hover: hover) {
  ul.location_icon li a:hover {
    color: #f8e703;
  }
}

.footer-main-content{
  display: flex;
  justify-content: space-between;
}

.form-section {
  width: 40%;
  min-width: 250px;
  margin-bottom: 20px;
}

.main_form {
  padding:0px;
}

.main_form .form-row {
  display: flex;
  flex-wrap: wrap;
}

.main_form .form-group {
  width: 100%;
}

.main_form .contactus {
  border: inherit;
  margin-bottom: 20px;
  width: 100%;
  height: 60px;
  background: transparent;
  color: #777977;
  font-size: 18px;
  font-weight: normal;
  border-bottom: #ddd solid 1px;
}

.main_form .textarea {
  border: inherit;
  margin-bottom: 20px;
  width: 100%;
  background: transparent;
  color: #777977;
  font-size: 18px;
  font-weight: normal;
  border-bottom: #ddd solid 1px;
  margin-top: 10px;
}

.button-group {
  width: 100%;
  text-align: left;
}

.main_form .send_btn {
  font-size: 17px;
  transition: ease-in all 0.5s;
  background-color:transparent;
  text-transform: uppercase;
  color: #fcba00;
  border: 3px solid #fcba00;
  padding: 10px 0px;
  max-width: 160px;
  font-weight: bold;
  width: 100%;
  display: block;
  margin-top: 30px;
}

@media (hover: hover) {
  .main_form .send_btn:hover {
    background-color: #fcba00;
    transition: ease-in all 0.5s;
    color: black;
  }
}

.social-group {
  width: 100%;
  text-align: left;
}

ul.social_icon {
  padding-top: 37px;
  list-style: none;
  display: flex;
  padding-left: 0;
}

ul.social_icon li {
  display: inline-block;
}

ul.social_icon li a {
  background: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  border-radius: 30px;
  margin: 0 3px;
  text-decoration: none;
  color: #000;
  transition: 0.4s;
}

@media (hover: hover) {
  ul.social_icon li a:hover {
    background-color: #fcba00;
  }
}

.map-section {
  min-width: 250px;
  margin-bottom: 20px;
  max-width: 45%;
}

.map figure {
  margin: 0;
}

.map figure img {
  width: 100%;
  filter: brightness(0) invert(1);
}

.bottom-form h6 {
  font-weight: 600;
  font-size: 20px;
  text-align: left;
  color: #fff;
  padding-bottom: 15px;
}

.bottom-form .enter {
  border: inherit;
  padding: 0px 19px;
  width: calc(100% - 100px);
  height: 53px;
  background: #fff;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  float: left;
  margin: 10px 0;
}

.sub_btn {
  font-size: 17px;
  transition: ease-in all 0.5s;
  background-color: #fcba00;
  color: black;
  padding: 11px 0px;
  margin-top: 10px;
  max-width: 200px;
  width: 100%;
  display: block;
  font-weight: bold;
  text-transform: uppercase;
  border: 3px solid #fcba00;
}

@media (hover: hover) {
  .sub_btn:hover {
    background-color: #000;
    transition: ease-in all 0.5s;
    color: #fcba00;
  }
}

.copyright {
  padding: 20px 0px;
}

.copyright p {
  color: #fff;
  font-size: 18px;
  line-height: 22px
}  

@media (max-width: 655px) {
  .footer-main-content{
    display: flex;
    flex-direction: column;
  }
  .form-section{
    width: 100%;
  }
  .contact-section ul li{
    text-align: start;
    font-size: 15px;
  }
  ul.location_icon {
    gap: 10px !important;
  }
  .button-group{
    text-align: center !important;
  }
  .main_form .send_btn{
    margin: 0 auto !important;
  }
  ul.social_icon{
    justify-content: center;
    padding-top: 20px;
  }
  .map-section{
    max-width: 80%;
    margin: 0 auto;
  }
}

@media (max-width: 530px) {
  .footer{
    padding-top: 0;
  }
  .titlepage{
    gap: 20px;
  }
  .titlepage h5{
    font-size: 19px;
  }
  .bottom-form h6{
    font-size: 18px;
  }
  .main_form .contactus {
    font-size: 15px !important;
    margin-bottom: 15px !important;
    height: 35px !important;
  }
  .main_form .send_btn {
    max-width: 130px !important;
    font-size: 14px !important;
  }
  .bottom-form .enter{
    font-size: 15px;
    height: 40px;
  }
  .sub_btn{
    font-size: 14px;
    padding: 7px 0;
  }
}

@media (max-width: 465px) {
  .footer{
    padding: 0 20px 15px 20px;
  }
  ul.location_icon {
    gap: 5px !important;
  }
  .titlepage{
    gap: 10px !important;
  }
  .titlepage h5{
    text-align: start;
    max-height: 45px;
  }
  .copyright p {
    font-size: 15px;
  }

}

@media (max-width: 420px) {
  .titlepage{
    flex-direction: column;
  }
  .main_form .contactus, .main_form .textarea {
    font-size: 13px !important;
    margin-bottom: 10px !important;
    height: 30px !important;
  }
  .map{
    width: 80%;
    margin: 0 auto;
  }
}
