.navbar-links button{
    color: #cccac4;
}

.active button{
    color: white;
    font-weight: bold;
}

.navbar-links hr{
    display: none;
}

.active hr{
    display: block;
    background-color: #fcba00;
    color: #fcba00;
    transform: translateY(-20px);
    width: 100%;
    transition: 1s;
}