.gallery{
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;
}

.gallery img{
    width: 50%;
    object-fit: contain;
    filter: brightness(80%);
}

@media (max-width: 530px) {
    .gallery{
        padding-bottom: 40px;
    }    
}

@media (max-width: 500px) {
    .gallery{
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
    .gallery img{
        width: 60%;
    }   
}
@media (max-width: 450px) {
    .gallery img{
        width: 80%;
    }   
}