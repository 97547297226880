.our-story-container {
    width: 90%;
    display: flex;
    gap: 20px;
    color: #fcba00;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    box-sizing: border-box;
}

.our-story-container img{
    width: 45%;
    height: auto;
    object-fit: contain;
}


.our-story-title {
    font-size: 32px;
    text-align: center;
    color: #fcba00;
    margin-bottom: 20px;
}

.our-story-description p {
    margin-bottom: 15px;
    font-size: 19px;
    line-height: 21.6px;
}
  
.our-story-container br{
    line-height: 20px;
}

@media (max-width:1030px) {
    .our-story-container {
        flex-wrap: wrap;
        justify-content: center;
    }
    .our-story-container img{
        width: 100%;
        min-width: 200px !important;
    }    
}

@media (max-width:700px) {
    .our-story-description p{
        font-size: 15px;
        line-height: 16px;
    }
}
