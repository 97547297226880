.service-header {
    min-height: 650px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    padding: 20px;
}
  
  .service-header_content {
    background: rgba(0, 0, 0, 0.7); /*Adding a dark overlay for better text visibility*/
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85%;
}
.works{
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px 0px;
}

.works-item{
    width: 30%;
    position: relative;
    min-width: 200px;
    cursor: pointer;
}
  
.works-item figure {
    margin: 0;
}

.works-item figure img {
    width: 100%;
    filter: brightness(50%);
}

.works-item-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    vertical-align: middle;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: ease-in all 0.5s;
    left: 0;
    right: 0;
}

@media (hover: hover) {
    .works-item-text {
        opacity: 0;
    }
    .works-item:hover .works-item-text{
        opacity: 1;
        transition: ease-in all 0.5s;
    }
}

.margin_bottom {
    margin-bottom: 30px;
}

.works-item-text h3 {
    color: #fff;
    font-size: 25px;
    line-height: 28px;
    font-weight: 700;
    padding-bottom: 10px;
    text-transform: uppercase;
}

.blu {
    color: #f8e703;
    font-size: 17px;
    line-height: 28px;
    font-weight: 600;
    text-transform: uppercase;
}

/* Responsive styles */
@media (max-width: 950px) {
    .works-item-text h3{
        font-size: 22px;
        padding: 0 25px;
    }
    .blu{
        font-size: 14px;
    }
}

@media (max-width: 840px) {
    .works{
        justify-content: center;
    }
    .works-item{
        min-width: 55%;
    }
}

@media (max-width: 540px) {
    .works-item{
        min-width: 70%;
    }
    .service-header_title h1{
        font-size: 25px;
    }
    .service-header_title p{
        font-size: 14px;
    }
}

@media (max-width: 400px) {
    .works-item{
        min-width: 80%;
    }
    .works-item-text h3{
        font-size: 17px;
        padding: 0 15px;
    }
    .blu{
        font-size: 11px;
    }
}