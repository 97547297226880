.carousel-container {
    width: 80%;
    overflow: hidden; /* Ensure no overflow */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    padding: 0 40px; /* Optional: adjust padding as needed */
    margin: 0 auto;
}
  
.review-card {
    background: black;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    margin: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width:90%; /* Adjust for 2 cards per slide */
    height: 300px;
    box-sizing: border-box; /* Ensure padding and border are included in the width */
}
  
.review-card__head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;  
}

.review-card__date {
    font-size: 17px;
    color: gray;
}

.review-card__message{
    margin-bottom: auto;
    color: #f9cc4e;
}
  
.rating{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.review-image {
    width: 100px;
    height: auto;
    border-radius: 50%;
    margin-bottom: 10px;
}
    
.review-card__name {
    margin: 10px 0;
    font-size: 27px;
    text-align: start;
    color: white;
}

.review-card .review-card__info{
    color: white;
}

.slick-list {
    overflow: hidden; /* Hide overflow to prevent horizontal scroll */
}
    
.slick-track {
    display: flex;
    align-items: center;
}    

@media (max-width: 600px) {
    .review-card__name{
        margin: 0;
    }
}